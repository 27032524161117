// import request from '@/utils/request'
import { get, postForm, postJson, } from "./http/http";

const httpServer = {
    // 查询任务列表
    getTaskTable(params) {
        return postJson("/task/task/table", params);
    },
    // 保存任务
    saveTask(params) {
        return postJson("/task/task/save", params);
    },
    // 删除任务
    delTask(params) {
        return get(`/task/task/delete/${params}`);
    },
    // 获取任务详情
    detailsTask(params) {
        return get("/task/task/findById", params);
    },
    // 出库设备列表
    listOutDevice(params) {
        return get("/emp/flow-item/findListByFlowId", params);
    },
    // 非赋码出库设备列表
    getNoCodeDeviceOutList(params) {
        return get("/emp/flow-item/findNoCodeListByFlowId", params);
    },
    // 移交任务
    changeOperator(params) {
        return get("/task/task/changeOperator", params);
    },
    // 维护管理 移交维护
    changeOperatorSafeguardManage(params) {
        return get("/maintain/maintain/changeOperator", params);
    },
    // 维护管理 详情
    detailsSafeguardManage(params) {
        return get("/maintain/maintain/findById", params);
    },
    // 维护管理 处理结果
    handleTask(params) {
        return postJson("/maintain/maintain/resolve", params);
    },
    // 维护管理 创建维护
    saveSafeguard(params) {
        return postJson("/maintain/maintain/save", params);
    },
    // 处理人员管理列表查询
    getHandPersonList(params) {
        return postForm("/maintain/maintain-person/findMaintainData", params);
    },
    // 处理人员管理保存
    saveHandPerson(params) {
        return postJson("/maintain/maintain-person/saveData", params);
    },
    // 获取设备故障列表
    getErrorItems(params) {
        return get(`/maintain/maintain/list-fault-types`, params);
    },

    // -------------------- 物料 --------------------
    // 物料清单
    getBomLis(params) {
        return postJson("/emp/matter-info/list", params);
    },
    // 保存物料清单
    saveBom(params) {
        return postJson("/emp/matter-info/save", params);
    },
    // 物料清单详情
    getBomDetails(id) {
        return get(`/emp/matter-info/info/${id}`);
    },
    // 删除物料清单
    delBom(id) {
        return get(`/emp/matter-info/deleteById/${id}`);
    },
    // -------------------- 硬件能力 | 检测项 --------------------
    // 硬件能力列表
    getPowerLis(params) {
        return get("/emp/device-capacity/list", params);
    },
    // 硬件能力保存
    savePower(params) {
        return postJson("/emp/device-capacity/save", params);
    },
    // 删除硬件能力
    delPower(id) {
        return get(`/emp/device-capacity/delete/${id}`);
    },
    // 硬件能力详情
    getPowerDetails(id) {
        return get(`/emp/device-capacity/findById/${id}`);
    },
    // 能力或检测项下拉
    getPowerList(type) {
        return get(`/emp/device-capacity/findByDetectType/${type}`);
    },
    // 根据设备类型查询物料清单
    getBomOpts(empTypeId) {
        return get(`/emp/matter-info/byTypeId/${empTypeId}`);
    },
    // 根据规格类型查询物料清单
    getBomByEmpTypeModelIdOpts(empTypeModelId) {
        return get(`/emp/matter-info/byModelId/${empTypeModelId}`);
    },
    // 检测记录列表
    getTestRecord(params) {
        return postJson("/emp/device-detection/table", params);
    },
    //检测记录详情
    getMatterRecord(detectId) {
        return get(`/emp/device-detection-details/list/${detectId}`);
    },

};

export default httpServer;
