// 物料清单管理
<template>

    <div class='bomManage baseBg'>
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     style="width: 100%"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="物料清单名称">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入物料清单名称"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="设备类型">
                    <SelectEmpType v-model="searchForm.empTypeId"
                                   placeholder="请选择设备类型"></SelectEmpType>
                </el-form-item>
                <el-form-item label="规格型号">
                    <SelectEmpTypeModel v-model="searchForm.empTypeModelId"
                                        :disabled="!searchForm.empTypeId"
                                        :searchData="{empTypeId: searchForm.empTypeId}"
                                        placeholder="请选择规格型号"></SelectEmpTypeModel>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
                <el-button type="success"
                           class="flr"
                           size="default"
                           @click="openpopEditBom()">新增物料清单</el-button>
            </el-form>
        </div>

        <div class="listBox"
             v-loading="loading">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          :height="tableHeight"
                          stripe>
                    <el-table-column prop="name"
                                     label="物料清单名称"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="empType"
                                     label="设备类型"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="provider"
                                     label="供应商"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="empTypeModel"
                                     label="规格型号"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="openpopBomDetails(scope.row)">查看</el-link>
                            <el-link type="primary"
                                     @click="openpopEditBom(scope.row)">编辑</el-link>
                            <el-popconfirm confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           icon="el-icon-info"
                                           icon-color="red"
                                           @confirm="del(scope.row)"
                                           title="真的要删除吗？">
                                <el-link type="danger"
                                         slot="reference">删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>

        <!-- 新增 | 编辑 物料清单 -->
        <PopEditBom v-if="popEditBomVisible"
                    :id="editInfo && editInfo.id"
                    @update="getList()"
                    @close="closepopEditBom"></PopEditBom>

        <!-- 查看物料清单 -->
        <PopBomDetails v-if="popBomDetailsVisible"
                       :editInfo="editInfo"
                       @close="closepopBomDetails"></PopBomDetails>

    </div>

</template>

<script>
import SelectEmpTypeModel from "../../components/commonSelect/selectEmpTypeModel";
import SelectEmpType from "../../components/commonSelect/selectEmpType";
import PopBomDetails from "./components/popBomDetails";
import PopEditBom from "./components/popEditBom";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
import API from "@/api/safeguardManage.js";
export default {
    name: "bomManage",

    props: [],

    components: {
        SelectEmpTypeModel,
        SelectEmpType,
        PopBomDetails,
        PopEditBom,
        Pagination,
    },

    data() {
        return {
            // 筛选表单
            searchForm: {
                name: "", // 物料清单名称
                empTypeId: "", // 设备类型
                empTypeModelId: "", // 规格型号
                page: 1,
                size: 20,
            },
            timeArr: [], // 检测时间
            empTypeIdOpts: [{ label: "全部", value: "" }], // 设备类型
            empTypeModelIdOpts: [{ label: "全部", value: "" }], // 规格型号

            pageTotal: 0, // 分页总数
            tableData: {
                data: [],
            },
            loading: false, // 列表加载中
            editInfo: null, // 编辑详情
            popEditBomVisible: false, // 新增 | 编辑 物料清单弹窗显示
            popBomDetailsVisible: false, // 查看物料清单弹窗显示
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 物料清单管理 列表
        this.getList();
    },

    mounted() {
        // 物料清单管理 列表
        this.getList();
    },

    methods: {
        // 物料清单管理 列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getBomLis(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除物料清单
        del(row) {
            this.loading = true;
            API.delBom(row.id)
                .then(() => {
                    this.loading = false;
                    // 物料清单管理 列表
                    this.getList();
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },

        //清空筛选条件
        resetSearchForm() {
            this.searchForm = {
                name: "", // 物料清单名称
                empTypeId: "", // 设备类型
                empTypeModelId: "", // 规格型号
                page: 1,
                size: 20,
            };
            // 查询列表
            this.getList();
        },
        // 新增 | 编辑 物料清单 打开弹框
        openpopEditBom(row = null) {
            this.editInfo = row;
            this.popEditBomVisible = true;
        },
        // 新增 | 编辑 物料清单 关闭弹框
        closepopEditBom() {
            this.editInfo = null;
            this.popEditBomVisible = false;
        },
        // 查看物料清单 打开弹框
        openpopBomDetails(row = null) {
            this.editInfo = row;
            this.popBomDetailsVisible = true;
        },
        // 查看物料清单 关闭弹框
        closepopBomDetails() {
            this.editInfo = null;
            this.popBomDetailsVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },
    },

    watch: {
        "searchForm.empTypeId": {
            deep: true,
            immediate: true,
            handler() {
                this.searchForm.empTypeModelId = null;
            },
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
