// 新增 | 编辑 物料清单
// popEditBom
<template>

    <div class="popEditBom">
        <el-dialog :title="`${id ? '编辑' : '新增'}物料清单`"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="750px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-position="top"
                     v-loading="detailsLoading"
                     :inline="false">
                <el-form-item label="物料清单名称"
                              prop="name">
                    <el-input v-model="form.name"
                              placeholder="请输入物料清单名称"
                              clearable></el-input>
                </el-form-item>

                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="设备类型"
                                      prop="empTypeId">
                            <SelectEmpType v-model="form.empTypeId"
                                           @change="changeEmpTypeIdObj"
                                           :apiParams="{haveCode: 'YES'}"
                                           placeholder="请选择设备类型"></SelectEmpType>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="供应商"
                                      prop="providerId">
                            <SelectProvider v-model="form.providerId"
                                            placeholder="请选择供应商"
                                            :searchData="{empTypeId: form.empTypeId}"
                                            @change="changeProviderIdObj"
                                            :disabled="!form.empTypeId"></SelectProvider>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="规格型号"
                                      prop="empTypeModelId">
                            <SelectEmpTypeModel v-model="form.empTypeModelId"
                                                :disabled="!form.empTypeId"
                                                :searchData="{empTypeId: form.empTypeId}"
                                                @change="changeempTypeModelIdObj"
                                                placeholder="请选择规格型号"></SelectEmpTypeModel>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="bomList mb20">
                    <div class="title">
                        <i class="red">*</i>&nbsp;&nbsp;物料清单
                        <el-button type="primary"
                                   @click="openpopAddBomItem()"
                                   size="mini">+</el-button>
                    </div>
                    <div class="bomListWarp"
                         v-if="form.matterDetailsList.length">
                        <div class="item"
                             v-for="(item,idx) in form.matterDetailsList"
                             :key="idx">
                            <!-- 设备类型/供应商/规格型号 -->
                            <div class="label">{{item.empType || "无"}}/{{item.provider || "无"}}/{{item.empTypeModel || "无"}}</div>
                            <div class="count">{{item.number}}</div>
                            <div class="controlBox">
                                <i class="el-icon-edit"
                                   @click="openpopAddBomItem(item, idx)"></i>
                                <i class="el-icon-delete"
                                   @click="delBomItem(idx)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="emptyBg"
                         style="height: 200px"
                         v-else></div>
                </div>

                <div class="footer"
                     v-loading="loading">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

        <!-- 添加物料 -->
        <PopAddBomItem v-if="popAddBomItemVisible"
                       :editInfo="editInfo"
                       @addBOMItem="addBOMItem"
                       @close="closepopAddBomItem"></PopAddBomItem>

    </div>
</template>

<script>
import SelectEmpType from "../../../components/commonSelect/selectEmpType";
import SelectProvider from "../../../components/commonSelect/selectProvider";
import SelectEmpTypeModel from "../../../components/commonSelect/selectEmpTypeModel";
import PopAddBomItem from "./popAddBomItem";
import API from "@/api/safeguardManage.js";
export default {
    name: "popEditBom",

    props: {
        // 编辑
        id: {
            default: null,
        },
    },

    components: {
        SelectEmpType,
        SelectProvider,
        SelectEmpTypeModel,
        PopAddBomItem,
    },

    data() {
        return {
            dialogVisible: true,
            form: {
                name: "", // 物料清单名称
                empTypeModelId: "", // 规格型号
                empTypeId: "", // 设备类型
                providerId: "", // 供应商
                matterDetailsList: [], // 物料清单
            },
            formRules: {
                name: {
                    required: true,
                    message: "请验证物料清单名称",
                }, // 物料清单名称
                empTypeModelId: {
                    required: true,
                    message: "请验证规格型号",
                }, // 规格型号
                empTypeId: {
                    required: true,
                    message: "请验证设备类型",
                }, // 设备类型
                providerId: {
                    required: true,
                    message: "请验证供应商",
                }, // 供应商
            },
            popAddBomItemVisible: false, // 添加物料弹窗显示
            editInfo: null, // 添加物料编辑信息
            editIdx: null, // 添加物料编辑序号
            loading: false, // 保存加载中
            detailsLoading: false, // 详情加载中
        };
    },

    created() {},

    mounted() {
        if (this.id) {
            this.form.id = this.id;
            // 详情
            this.getDetails();
        }
    },

    methods: {
        // 详情
        getDetails() {
            this.detailsLoading = true;
            API.getBomDetails(this.form.id)
                .then((res) => {
                    this.detailsLoading = false;
                    let data = res.content;
                    for (
                        let index = 0;
                        index < data.matterDetailsList.length;
                        index++
                    ) {
                        // 详情传了haveCode 但页面不需要 然后保存的时候还不能传haveCode
                        const item = data.matterDetailsList[index];
                        delete item.haveCode;
                    }
                    this.form = data;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 保存
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.matterDetailsList.length > 0) {
                        this.loading = true;
                        API.saveBom(this.form)
                            .then(() => {
                                this.loading = false;
                                this.$message({
                                    message: "操作成功！",
                                    type: "success",
                                });
                                this.$emit("update");
                                this.close();
                            })
                            .catch(() => {
                                this.loading = false;
                            });
                    } else {
                        this.$message({
                            message: "请添加物料清单！",
                            type: "error",
                        });
                    }
                }
            });
        },
        // 子组件添加物料项
        addBOMItem(item) {
            if (this.editInfo) {
                this.form.matterDetailsList.splice(this.editIdx, 1, item);
            } else {
                this.form.matterDetailsList.push(item);
            }
            this.closepopAddBomItem();
        },
        // 添加物料 打开弹框
        openpopAddBomItem(item = null, editIdx = null) {
            this.editInfo = item;
            this.editIdx = editIdx;
            this.popAddBomItemVisible = true;
        },
        // 添加物料 关闭弹框
        closepopAddBomItem() {
            this.editInfo = null;
            this.editIdx = null;
            this.popAddBomItemVisible = false;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 切换设备类型
        changeEmpTypeIdObj() {
            this.form.providerIdObj = null;
            this.form.providerId = null;
            this.form.provider = null;
            this.form.empTypeModelIdObj = null;
            this.form.empTypeModelId = null;
            this.form.empTypeMode = null;
        },
        // 切换供应商
        changeProviderIdObj() {
            this.form.empTypeModelIdObj = null;
            this.$nextTick(() => {
                if (this.form.providerIdObj) {
                    this.form.providerId = this.form.providerIdObj.value;
                    this.form.provider = this.form.providerIdObj.label;
                    this.$refs.form.validateField(["providerId"]);
                }
            });
        },
        // 切换规格型号
        changeempTypeModelIdObj() {
            this.$nextTick(() => {
                if (this.form.empTypeModelIdObj) {
                    this.form.empTypeModelId =
                        this.form.empTypeModelIdObj.value;
                    this.form.empTypeMode = this.form.empTypeModelIdObj.label;
                    this.$refs.form.validateField(["empTypeModelId"]);
                }
            });
        },
        // 删除物料
        delBomItem(index) {
            this.form.matterDetailsList.splice(index, 1);
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.bomList {
    background: #ffffff;
    border: 1px solid #ebeef5;
    .title {
        font-size: 16px;
        line-height: 36px;
        color: #333333;
        background: #e8f2fa;
        border-radius: 0px;
        padding: 0 20px;
        .el-button {
            width: 25px;
            height: 25px;
            line-height: 25px;
            padding: 0;
            font-size: 20px;
            float: right;
            margin-top: 5px;
        }
    }
    .bomListWarp {
        padding: 20px 20px 0;
        overflow: hidden;
        height: 300px;
        overflow-y: auto;
        .item {
            width: 100%;
            padding: 15px 20px;
            line-height: 19px;
            border: 1px solid #dcdfe6;
            float: left;
            margin-bottom: 20px;
            margin-right: 20px;
            display: flex;
            .label {
                font-size: 14px;
                color: #333333;
                flex: 1;
            }
            .count {
                width: 80px;
                text-align: right;
                font-size: 20px;
                font-family: DIN;
                color: #333333;
            }
            .controlBox {
                width: 80px;
                i {
                    font-size: 20px;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
        }
        .item:nth-child(2n) {
            margin-right: 0;
        }
    }
}
</style>