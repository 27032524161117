// 选择规格型号
<template>

    <div class="selectEmpTypeModel">
        <el-select v-model="empTypeModel"
                   :filterable="filterable"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :clearable="clearable"
                   @change="change">
            <el-option v-for="item in options"
                       :key="returnType === 'String' ? item.value : item"
                       :label="item.label"
                       :value="returnType === 'String' ? item.value : item">
            </el-option>
        </el-select>
    </div>

</template>
<script>
import commonAPI from "@/api/commonAPI.js";

export default {
    name: "selectEmpTypeModel",
    components: {},

    props: {
        // v-model绑定的值
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "请选择",
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // 下拉搜索条件
        searchData: {
            type: Object,
            default: null,
        },
        // 返回值类型 ["String", "Object"]
        returnType: {
            type: String,
            default: "String",
        },
    },

    data() {
        return {
            empTypeModel: "",
            options: [], // 选项
        };
    },

    created() {
    },

    mounted() {
        // 获取规格型号下拉数据
        this.getOpts();
    },

    methods: {
        // 获取规格型号下拉数据
        getOpts() {
            commonAPI
                .getTypeModelOpts(this.searchData && this.searchData.empTypeId)
                .then((res) => {
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {label: val["label"], value: val["code"]};
                    });
                    this.options = opt;
                });
        },
        // 触发change事件
        change() {
            this.$emit("change");
        },
    },

    computed: {},

    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(newValue) {
                this.empTypeModel = newValue;
            },
        },
        empTypeModel() {
            this.$emit("input", this.empTypeModel);
        },
        searchData: {
            deep: true,
            immediate: true,
            handler(search) {
                if (search && search.empTypeId) {
                    // 获取规格型号下拉数据
                    this.getOpts();
                }
            },
        },
    },
};
</script>