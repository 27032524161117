// 查看物料清单
// popBomDetails
<template>

    <div class="popBomDetails">
        <el-dialog title="查看物料清单"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="760px"
                   :before-close="close">

            <div v-loading="loading">
                <div class="headInfo">
                    <div class="mainInfo">
                        <div class="name">{{detailsInfo.name}}</div>
                        <div class="content">{{detailsInfo.empType || "无"}}-{{detailsInfo.provider || "无"}}-{{detailsInfo.empTypeModel || "无"}}</div>
                    </div>
                </div>

                <div class="bomList mb20">
                    <div class="title">物料清单</div>
                    <div class="bomListWarp"
                         v-if="detailsInfo.matterDetailsList.length">
                        <div class="item"
                             v-for="(item,idx) in detailsInfo.matterDetailsList"
                             :key="idx">
                            <div class="label">{{item.empType || "无"}}/{{item.provider || "无"}}/{{item.empTypeModel || "无"}}</div>
                            <div class="count">{{item.number}}</div>
                        </div>
                    </div>
                    <div class="emptyBg"
                         style="height: 200px"
                         v-else></div>
                </div>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import API from "@/api/safeguardManage.js";
export default {
    name: "popBomDetails",

    props: {
        // 编辑详情
        editInfo: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            loading: false, // 详情加载中
            detailsInfo: null, // 详情
        };
    },

    created() {},

    mounted() {
        // 详情
        this.getDetails();
    },

    methods: {
        // 详情
        getDetails() {
            this.loading = true;
            API.getBomDetails(this.editInfo.id)
                .then((res) => {
                    this.loading = false;
                    this.detailsInfo = res.content;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.headInfo {
    margin-bottom: 30px;
    display: flex;
    .headPic {
        width: 64px;
        height: 64px;
        background: #003a81;
        border-radius: 8px;
    }
    .mainInfo {
        padding-left: 20px;
        flex: 1;
        .name {
            font-size: 20px;
            line-height: 26px;
            color: #333333;
            margin-bottom: 11px;
        }
        .count {
            font-size: 14px;
            line-height: 19px;
            color: #666666;
        }
    }
}
.bomList {
    background: #ffffff;
    border: 1px solid #ebeef5;
    .title {
        font-size: 16px;
        line-height: 36px;
        color: #333333;
        background: #e8f2fa;
        border-radius: 0px;
        padding: 0 20px;
    }
    .bomListWarp {
        padding: 20px 20px 0;
        overflow: hidden;
        .item {
            width: calc(50% - 10px);
            padding: 15px 20px;
            line-height: 19px;
            border: 1px solid #dcdfe6;
            float: left;
            margin-bottom: 20px;
            margin-right: 20px;
            display: flex;
            .label {
                font-size: 14px;
                color: #333333;
                flex: 1;
            }
            .count {
                width: 80px;
                text-align: right;
                font-size: 20px;
                font-family: DIN;
                color: #333333;
            }
        }
        .item:nth-child(2n) {
            margin-right: 0;
        }
    }
}
</style>