// 添加物料
// popAddBomItem
<template>

    <div class="popAddBomItem">
        <el-dialog :title="`${editInfo ? '编辑' : '添加'}物料`"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="400px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-width="80px"
                     :inline="false">

                <el-form-item label="设备类型"
                              prop="empTypeId">
                    <SelectEmpType v-model="form.empTypeIdObj"
                                   returnType="Object"
                                   @change="changeEmpTypeIdObj"
                                   placeholder="请选择设备类型"></SelectEmpType>
                </el-form-item>
                <el-form-item label="供应商"
                              prop="providerId">
                    <SelectProvider v-model="form.providerIdObj"
                                    returnType="Object"
                                    placeholder="请选择供应商"
                                    :searchData="{empTypeId: form.empTypeId}"
                                    @change="changeProviderIdObj"
                                    :disabled="!form.empTypeId"></SelectProvider>
                </el-form-item>
                <el-form-item label="规格型号"
                              prop="empTypeModelId">
                    <SelectEmpTypeModel v-model="form.empTypeModelIdObj"
                                        returnType="Object"
                                        :disabled="!form.empTypeId"
                                        :searchData="{empTypeId: form.empTypeId}"
                                        @change="changeempTypeModelIdObj"
                                        placeholder="请选择规格型号"></SelectEmpTypeModel>
                </el-form-item>
                <el-form-item label="数量"
                              prop="number">
                    <el-input-number v-model="form.number"
                                     min="0"
                                     precision="0"
                                     :controls="false"
                                     placeholder="请输入数量"
                                     clearable></el-input-number>
                </el-form-item>
                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save">保存</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import SelectEmpTypeModel from "../../../components/commonSelect/selectEmpTypeModel";
import SelectProvider from "../../../components/commonSelect/selectProvider";
import SelectEmpType from "../../../components/commonSelect/selectEmpType";
export default {
    name: "popAddBomItem",

    props: {
        // 编辑内容
        editInfo: {
            type: Object,
            default: null,
        },
    },

    components: {
        SelectEmpTypeModel,
        SelectProvider,
        SelectEmpType,
    },

    data() {
        let checkNumber = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请验证数量"));
            }
            if (value < 1) {
                callback(new Error("数量必须大于1"));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: true,

            form: {
                empTypeModelId: "", // 规格型号
                empTypeId: "", // 设备类型
                providerId: "", // 供应商
                number: "", // 数量
            },
            formRules: {
                empTypeModelId: {
                    required: true,
                    message: "请验证规格型号",
                }, // 规格型号
                empTypeId: {
                    required: true,
                    message: "请验证设备类型",
                }, // 设备类型
                providerId: {
                    required: true,
                    message: "请验证供应商",
                }, // 供应商
                number: {
                    required: true,
                    validator: checkNumber,
                    trigger: "blur",
                }, // 数量
            },
        };
    },

    created() {},

    mounted() {
        if (this.editInfo) {
            let data = Object.assign({}, this.editInfo);
            if (data.empTypeModelId) {
                data.empTypeModelIdObj = {
                    label: data.empTypeModel,
                    value: data.empTypeModelId,
                };
            }
            if (data.empTypeId) {
                data.empTypeIdObj = {
                    label: data.empType,
                    value: data.empTypeId,
                };
            }
            if (data.providerId) {
                data.providerIdObj = {
                    label: data.provider,
                    value: data.providerId,
                };
            }
            this.form = data;
        }
    },

    methods: {
        // 添加物料
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$emit("addBOMItem", this.form);
                }
            });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 切换设备类型
        changeEmpTypeIdObj() {
            this.form.providerIdObj = null;
            this.form.providerId = null;
            this.form.provider = null;
            this.form.empTypeModelIdObj = null;
            this.form.empTypeModelId = null;
            this.form.empTypeMode = null;
        },
        // 切换供应商
        changeProviderIdObj() {
            this.form.empTypeModelIdObj = null;
            this.$nextTick(() => {
                if (this.form.providerIdObj) {
                    this.form.providerId = this.form.providerIdObj.value;
                    this.form.provider = this.form.providerIdObj.label;
                    this.$refs.form.validateField(["providerId"]);
                }
            });
        },
        // 切换规格型号
        changeempTypeModelIdObj() {
            this.$nextTick(() => {
                if (this.form.empTypeModelIdObj) {
                    this.form.empTypeModelId =
                        this.form.empTypeModelIdObj.value;
                    this.form.empTypeModel = this.form.empTypeModelIdObj.label;
                    this.$refs.form.validateField(["empTypeModelId"]);
                }
            });
        },
    },

    computed: {},

    watch: {
        // 设备类型
        "form.empTypeIdObj": {
            deep: true,
            handler() {
                if (this.form.empTypeIdObj) {
                    this.form.empTypeId = this.form.empTypeIdObj.value;
                    this.form.empType = this.form.empTypeIdObj.label;
                }
            },
        },
        // 供应商
        "form.providerIdObj": {
            deep: true,
            handler() {
                if (this.form.providerIdObj) {
                    this.form.providerId = this.form.providerIdObj.value;
                    this.form.provider = this.form.providerIdObj.label;
                }
            },
        },
        // 规格型号
        "form.empTypeModelIdObj": {
            deep: true,
            handler() {
                if (this.form.empTypeModelIdObj) {
                    this.form.empTypeModelId =
                        this.form.empTypeModelIdObj.value;
                    this.form.empTypeMode = this.form.empTypeModelIdObj.label;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
</style>